import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/awards-and-press.scss"
import defaultImg from "../assets/images/defAward.jpg"
import teamImg from "../assets/images/Team-machstatz.jpg"
import elevate100Img from "../assets/images/Elevate100_Certificate.jpg"
import eSparkImg from "../assets/images/E-Spark-Viridian-Award.jpg"
import pressImg from "../assets/images/press-image-1.jpg"
import pressImg1 from "../assets/images/pressIIIT.png"

const awardsData = [
    {
        title:'SmartUp 2017 a Pan India Business Plan Competition',
        content:'Espark-Viridian, a startup accelerator for early and growth-seeking startups in-association with Headstart Network Foundation, announced the winners of its – SmartUp 2017 a Pan India Business Plan Competition, held from 13th-14th October at Kirori Mal College in North Campus, Delhi University.',
        image:teamImg
    },
    {
        title:'Elevate100 Certificate',
        content:'Machstatz was Runner’s Up for Elevate100 event organized by Government of Karnataka. ELEVATE 100, an initiative of the Department of Information Technology and Biotechnology, Government of Karnataka aims to provide a comprehensive entrepreneurship platform for startups.',
        image:elevate100Img
    },
    {
        title:'E-Spark Viridian Award',
        content:'Machstatz won 1st Runner’s Up award for a PAN India Business Plan Contest organized by UK based accelerator E-Spark , Viridian Ventures & Headsart.',
        image:eSparkImg
    }
]
const pressData = [
    {
        title:'Economic Times Power of Ideas 2018',
        subTitle:'Machstatz is among the winners of Economic Times Power of Ideas 2018',
        content:'We are honored to be felicitated by Sri. Suresh Prabhu,Union Minister of Commerce & Industry and Civil Aviation...',
        image:pressImg,
        link:'https://www.etpowerofideas.com'
    },
    {
        title:'Startup Accelerator Espark Viridian Announces Winners Of Its ‘SmartUp’ B-Plan Competition',
        subTitle:'Machstatz is the runner-up of SmartUp 2017, A Pan-India B-Plan Competition Was Held For Startups From 13th-14th October In Delhi',
        content:'Espark-Viridian, a startup accelerator for early and growth-seeking startups in-associatio with Headstart Network...',
        image:'',
        link:'https://inc42.com/buzz/startup-accelerator-espark-viridian/'
    },
    {
        title:'Funding Galore: Indian Startup Funding Of The Week [23-28 October]',
        subTitle:'Machstatz is among the 19 startups that received funding In The Indian Startup Ecosystem',
        content:'The three startups which are selected as the final three prize winners included Pune based Marketyard Agrisolutions, Bengaluru based Machstatz...',
        image:'',
        link:'https://inc42.com/buzz/indian-startup-funding-22/'
    },
    {
        title:'IIoT Solutions by Machstatz',
        subTitle:'Founded by Sameer Patnaik, Srikant Gupta and Pruthviraj Subudhi, Machstatz Business Solutions provides an end-to-end platform in the industrial IoT field.',
        content:'Founded by Sameer Patnaik, Srikant Gupta and Pruthviraj Subudhi, Machstatz Business Solutions provides an end-to-end platform...',
        image:'',
        link:'https://smart.electronicsforu.com/iiot-solutions-machstatz/'
    },
    {
        title:'Artificial Intelligence & Machine Learning Critical Factors in Shaping IoT',
        subTitle:'Machstatz is a Bangalore-based IoT company serving Industry 4.0 as a creator through Quasar, its complete Industrial IoT package, smart edge computing device, gateways and analytics.',
        content:'Machstatz is a Bangalore-based IoT company serving Industry 4.0 as a creator through Quasar, its complete Industrial IoT package...',
        image:'',
        link:'https://iot.electronicsforu.com/content/directory/products-solutions/industrial/artificial-intelligence-machine-learning-critical-factors-in-shaping-iot/'
    },
    {
        title:'CIE at IIIT Hyderabad announces the start of its next cohort of Deep tech Accelerators',
        subTitle:'Machstatz provides end to end solution in Industrial IoT. They use artificial intelligence and machine learning algorithms to provide predictive and process analytics solutions to manufacturing industries.',
        content:'Machstatz uses flexible models, proven diagnostic instruments, sophisticated software and unmatched analytic expertise to deliver sustainable, scalable and cost effective...',
        image:pressImg1,
        link:'//www.businessnewsthisweek.com/entrepreneurship/cie-at-iiit-hyderabad-announces-the-start-of-its-next-cohort-of-deep-tech-accelerators/'
    },
    {
        title:'CIE-IIIT Hyderabad Selects Startups Machstatz, eSkindoctor For Next Cohort',
        subTitle:'IIITH is an autonomous research university founded in 1998 that focuses on the core areas of Information Technology, such as Computer Science, Electronics and Communications, and their applications in other domains through inter-disciplinary research with great social impact.',
        content:'Started in 2017 by the founders Sameer Patnaik, Pruthviraj Subudhi and Srikant Gupta, Machstatz ...',
        image:'',
        link:'https://www.thenfapost.com/2021/01/21/cie-iiit-hyderabad-selects-startups-machstatz-eskindoctor-for-next-cohort/'
    },
    {
        title:'CIE @ IIIT-H unveils 2 deep tech startups',
        subTitle:'Machstatz offers smart devices along with connectivity, communication, software analytics, dashboarding and reporting in order to provide a complete solution to its customers. Started in 2017 by Sameer Patnaik, Pruthviraj Subudhi and Srikant Gupta',
        content:'Machstatz offers smart devices along with connectivity, communication, software analytics,...',
        image:'',
        link:'https://epaper.thehansindia.com/Home/MShareArticle?OrgId=21173e0c28a&imageview=0'
    },
    {
        title:'Start-ups raise Rs 6 crore at finale',
        subTitle:'The companies that raised funds included Matchstatz Solutions Pvt Ltd, a Karnataka-based start-up, which provides solutions for Industrial Internet of Things (IIoT) and RidezNow, which provides rental conveyance service to the hostel students of Chitkara University.',
        content:'Nearly half a dozen start-ups from Chandigarh and other parts of the country raised around Rs 6 crore on the spot at the finale of the fourth edition of India Fund Fest 2020...',
        image:'',
        link:'https://www.tribuneindia.com/news/start-ups-raise-rs-6-crore-at-finale-37660?fbclid=IwAR1dMypt9wVODytbZ65_4ExvJFKqkeUjBJunNXsOse-iuEqfsU4rN02JZKQ'
    },
    {
        title:'Chitkara University Hosts “India Fund Fest 2020”',
        subTitle:'Machstatz Business Solutions Pvt. Ltd. founded by Sameer Patnaik, A.Srikant Gupta, Pruthviraj Subudhi & Incubated by Derbi Foundation, providing end to end solution in the IIoT (Industrial Internet of Things) field using artificial intelligence algorithms and machine learning',
        content:'The fourth edition of India Fund Fest finale was held at the Hyatt Regency Hotel in Chandigarh on 7th February 2020. The finale saw twenty eight finalists pitching to fourteen investors...',
        image:'',
        link:'https://www.5dariyanews.com/news/282058-Chitkara-University-Hosts-%e2%80%9cIndia-Fund-Fest-2020%e2%80%9d'
    },
    
]


const Awards = () => (
    <Layout>
        <SEO title="Awards" />
        <div className="container">
            <h1 className="text-center pt-lg-2 pt-md-1 ">Awards & Recognitions</h1>
            {awardsData.map(item=>{
                return(
                  <div>
                  <div className="row p-lg-2 p-md-0 mt-5">
                <div className="col-lg-6 my-auto">
                    <h5 className="sub-title-awards">{item.title}</h5>
                    <p className="read-more-awards">
                        {item.content}
                    </p>
                </div>
                <div className="col-lg-6 text-center">
                    <img src={item.image} className="awards-img"></img>
                </div>
            </div>
            <br />
            </div>
                )
            })}
            
            <h2 className="text-center pt-3 pt-lg-5 title-press mt-5">Press</h2>
            {pressData.map(item=>{
                return (<div className="row press-div">
                <div className="col-12 col-lg-5 col-md-5 p-0 text-center">
                    <img src={item.image ? item.image : defaultImg} width="300px" className="img-fluid pt-lg-4 pt-md-0 text-center"></img>
                </div>
                <div className="col-12 col-lg-7 col-md-7 text-left p-0 p-md-2 mt-4 mt-lg-0 mt-md-0">
                    
                        <h6 className="sub-heading-press">{item.title}</h6>
                    
                    {/* <p className="sub-title-press">{item.subTitle}</p> */}
                    <p className="font-14-awards">{item.content}</p><a target="_blank" href={item.link} style={{color:'#4487C0'}}><p>Continue reading</p></a>
                </div>
            </div>)
            })}
            
        </div>
    </Layout>
)

export default Awards
